<template>
  <div class="mb-12">
    <ConfirmModal
      v-if="showModal"
      :title="t('BoothExtras.extrasPromptTitle')"
      :description="t('BoothExtras.extrasPromptText')"
      :cancel="t('BoothExtras.promptCancelButton')"
      :approve="t('BoothExtras.promptApprovalButton')"
      @close-dialog="closeDialog"
    />

    <h1>
      {{ t('Booth.headline', { name: booth.boothNumber }) }}
      {{ t('Booth.ofPartnerCompany', { name: booth.partnerCompany?.name }) }}
    </h1>

    <p class="mt-4 text-body-m">
      {{ t('BoothExtras.description', { dueDate }) }}
    </p>

    <div class="bg-white p-4 pt-0 my-4">
      <ExpandablePanel v-for="(group, index) in extraGroups" :key="index">
        <template #header="{ togglePanel, isPanelOpen }">
          <div class="flex items-center cursor-pointer" @click="togglePanel()">
            <ExtrasGroupHeader
              :name="group.name"
              :extras="group.extras"
              :expires-at="group.expiresAt"
              :open="isPanelOpen"
              :class="isExpired(group.expiresAt) ? 'grey--text' : ''"
            />
            <SvgIcon :path="isPanelOpen ? mdiChevronUp : mdiChevronDown" :size="25" class="text-grey-800 ml-1" />
          </div>
        </template>
        <template #content>
          <div class="mt-4">
            <TableLight>
              <thead>
                <tr>
                  <th class="text-left" width="45%">
                    {{ t('BoothExtras.item') }}
                  </th>
                  <th class="text-left" width="15%">
                    {{ t('BoothExtras.pricePerItem') }}
                  </th>
                  <th class="text-left" width="25%">
                    {{ t('BoothExtras.amount') }}
                  </th>
                  <th class="text-right" width="15%">
                    {{ t('BoothExtras.total') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ExtraItem
                  v-for="extra in group.extras"
                  :key="extra.product.id"
                  :product="extra.product"
                  :count="extra.count"
                  :disabled="isExpired(group.expiresAt)"
                  :is-reduceable="isReduceable(group.cancellationDate)"
                  :minimum-value="extra.minimumValue"
                  :selected-variant="extra.selectedVariant"
                  :old-file-name="extra.oldFileName"
                  :comment="extra.comment"
                  :class="isExpired(group.expiresAt) ? 'grey--text' : ''"
                  @update="updateExtras"
                />
              </tbody>
            </TableLight>
          </div>
        </template>
      </ExpandablePanel>
    </div>

    <div class="bottom-bar responsive-container">
      <div class="d-flex flex-col-reverse flex-md-row justify-space-between align-left align-md-center p-4">
        <RouterLink class="btn-primary-purple-m" :to="{ name: 'boothDetails', params: { boothId: boothId } }">
          <SvgIcon :path="mdiArrowLeft" :size="24" class="mr-2" />
          {{ t('BoothExtras.cancelButton') }}
        </RouterLink>
        <div class="d-flex items-center justify-space-between justify-md-center mb-5 mb-md-0">
          <span class="white--text"
            >{{ t('BoothExtras.netoSum') }}
            <span class="text-h6 black-text pr-3 pl-3">
              {{ formatPrice(extrasSum) }}
            </span>
          </span>

          <button :disabled="saving" class="ml-3 btn-primary-purple-m" @click.stop="openDialog()">
            <span v-if="!saving && uploadProgress === 0">{{ t('BoothExtras.saveCTA') }}</span>
            <span v-if="saving && 100 > uploadProgress && uploadProgress > 0">{{ uploadProgress }}% {{ t('EventForm.uploaded') }}</span>
            <span v-if="saving && uploadProgress === 100">{{ t('EventForm.processing') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
type ExtraSelection = {
  productId: string
  variantId?: string
  count: number
  minimumValue?: number
  file?: File
  oldFileName?: string
  comment?: string
}
export type ExtraProduct = {
  categoryId: string
  categoryName: string
  position: number
  product: Product
  count: number
  minimumValue: number
  selectedVariant: Variant
  oldFileName: string
  comment?: string
}
type ProductGroup = {
  name: string
  position: number
  cancellationDate: string
  expiresAt: string
  extras: ExtraProduct[]
}
</script>

<script setup lang="ts">
import { ExtraItem, ExtrasGroupHeader } from '@/components/Order'
import { computed, ref, type Ref, onMounted } from 'vue'
import type { Booth, ExtraAttributes, Variant, Product } from '@/gql/myomr'
import { useUpdateBoothExtrasMutation } from '@/gql/myomr'
import { formatPrice } from '@/helpers/moneyHelper'
import { showNotification } from '@/helpers/notificationHelper'
import ConfirmModal from '@/components/ConfirmModal.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import TableLight from '@/ui/TableLight.vue'
import { mdiArrowLeft, mdiChevronDown, mdiChevronUp } from '@mdi/js'

const props = defineProps<{
  booth: Booth
  boothId: string
}>()

const { d, t } = useI18n()
const router = useRouter()

const uploadProgress = ref(0)
const selectedExtras = ref<ExtraSelection[]>([])
const showModal = ref(false)

const {
  mutate,
  loading: saving,
  onDone,
  onError,
} = useUpdateBoothExtrasMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        uploadProgress.value = Math.round((progress.loaded / progress.total) * 100)
      },
    },
  },
})

onDone((result) => {
  if (result.data?.updateBoothExtras?.booth) {
    router.push({
      name: 'boothDetails',
      params: { boothId: props.boothId },
      hash: '#booth-extras',
    })
    showNotification(t('BoothExtras.saveSuccess').toString(), 'success')
  } else if (result.data?.updateBoothExtras?.errors.length) {
    const error = result.data?.updateBoothExtras?.errors.join(', ')
    if (error) showNotification(error, 'error')
  }
})

onError((error) => {
  if (error) showNotification(error.message, 'error')
})

const extraGroups = computed<ProductGroup[]>(() => {
  const groups = extras.value.reduce((groups: any, extra) => {
    groups[extra.categoryId] = groups[extra.categoryId] || {
      name: extra.categoryName,
      position: extra.position,
      cancellationDate: extra.cancellationDate,
      expiresAt: extra.expiresAt,
      extras: [],
    }
    groups[extra.categoryId].extras.push(extra)

    return groups
  }, {})

  const productGroups: ProductGroup[] = Object.values(groups)

  return productGroups.sort((a: ProductGroup, b: ProductGroup) => {
    if (a.position < b.position) return -1
    if (a.position > b.position) return 1
    return 0
  })
})

function isExpired(expiryDate: string | null) {
  if (!expiryDate) return false
  return new Date(expiryDate) < new Date()
}

function isReduceable(cancellationDate: string | null) {
  if (!cancellationDate) return false
  return new Date(cancellationDate) > new Date()
}

const extras = computed(() => {
  return props.booth.products.map((product) => {
    const extra = selectedExtras.value.find((extra) => extra.productId === product.id)

    let selectedVariant

    if (extra?.variantId) {
      selectedVariant = product.variants.find((variant) => variant.id === extra?.variantId)
    } else if (product.variants.length === 1) {
      selectedVariant = product.variants[0]
    }

    return {
      categoryId: product.productCategory?.id || 'other',
      categoryName: product.productCategory?.name || t('BoothExtras.otherExtras'),
      position: product.productCategory?.position || 1000,
      product: product,
      cancellationDate: product.productCategory?.cancellationDate,
      expiresAt: product.productCategory?.expiresAt,
      count: extra?.count || 0,
      minimumValue: extra?.minimumValue || 0,
      selectedVariant: selectedVariant,
      oldFileName: extra?.oldFileName,
      comment: extra?.comment,
    }
  })
})

function extraIndexByProductId(product: string) {
  return selectedExtras.value.findIndex((extra) => extra.productId === product)
}

function closeDialog(doSaveExtras: boolean) {
  showModal.value = false
  if (doSaveExtras) saveExtras()
}

function openDialog() {
  if (hasErrors.value) {
    window.scrollTo(0, 0)
    showNotification(t('BoothExtras.validationErrorInline'), 'error')
  } else {
    showModal.value = true
  }
}

async function saveExtras() {
  const extraAttrs: ExtraAttributes[] = selectedExtras.value.map((extra) => {
    return {
      productId: extra.productId,
      variantId: extra.variantId || '',
      count: extra.count,
      comment: extra.comment,
      file: extra.file || undefined,
    }
  })

  mutate({
    boothId: props.booth.id,
    extras: extraAttrs,
  })
}

function upsertExtra(productId: string, count: number, variantId?: string, uploadedFile?: File, comment?: string) {
  const index = extraIndexByProductId(productId)

  if (index > -1) {
    selectedExtras.value[index].count = count
    selectedExtras.value[index].variantId = variantId
    selectedExtras.value[index].file = uploadedFile
    selectedExtras.value[index].comment = comment
  } else {
    const extra: ExtraSelection = {
      productId: productId,
      variantId: variantId,
      count: count,
      comment: comment,
      file: uploadedFile,
    }

    selectedExtras.value.push(extra)
  }
}

// Currently not in use:
// function deleteExtra(productId: string) {
//   let index = extraIndexByProductId(productId)
//   if (index > -1) {
//     selectedExtras.value.splice(index, 1)
//   }
// }

function updateExtras(productId: string, count: number, variantId?: string, uploadedFile?: Ref<File>, comment?: string) {
  upsertExtra(productId, count, variantId, uploadedFile?.value, comment)
}

const hasErrors = computed(() => {
  return selectedExtras.value.some((extra) => {
    if (!extra.variantId) return true
    if (findProductById(extra.productId)?.fileUploadRequired && !extra.file && !extra.oldFileName) {
      return true
    }
    if (findProductById(extra.productId)?.commentRequired && extra?.comment?.length === 0) {
      return true
    }

    return false
  })
})

const extrasSum = computed(() => {
  return selectedExtras.value.reduce((sum, extra) => {
    const product = findProductById(extra.productId)

    const variant = product?.variants.find((variant) => variant.id === extra.variantId)

    const price = variant?.price || 0
    return sum + (extra.count * price + (product?.deliveryFee || 0))
  }, 0)
})

function findProductById(productId: string) {
  return props.booth.products.find((product) => product.id === productId)
}

onMounted(() => {
  if (props.booth.extras) {
    selectedExtras.value = props.booth.extras.map((extra) => {
      return {
        productId: extra.productId,
        variantId: extra.selectedVariantId,
        count: extra.count,
        comment: extra.comment || undefined,
        minimumValue: extra.count,
        oldFileName: extra.fileName || undefined,
      }
    })
  }
})

const dueDate = computed(() => {
  if (props.booth.extrasDueDate) {
    return d(props.booth.extrasDueDate, 'dateShort')
  }

  return null
})
</script>

<style lang="scss" scoped>
.bottom-bar {
  width: 100%;
  height: auto;
  background: black;
  position: absolute;
  bottom: 0;
  left: 0;
}

.black-text {
  font-weight: 900;
  font-style: italic;
}
</style>
